<template>
  <a-modal
      :getContainer="getRefsAff"
      :visible="visible"
      @cancel="closeModal"
      :width="locationName==='floor01'? '40vw' :'76vw'"
      class="check-seatpositions"
      centered
      :footer="null"
  >
    <div ref="seatWrap" class="wrap">
      <div class="seatImg" :class="locationName" ref="seatImg">
        <template v-for="(item,index) in seatData" :key="index">
          <Tooltip :title="item.code">
            <div
                class="icon-list icon_seat_blue"
                :class="[
                    'seat-num-'+item.name,
                    item.safety==='0'&&item.status==='1'?'icon_seat_red':'', //普通不能坐
                    item.safety==='1'&&item.status==='0'?'icon_seat_white_border':'', //隔离可坐
                    item.safety==='1'&&item.status==='1'?'icon_seat_red_border':'', //隔离不能坐
                    checkSeat(item.name)
                ]"
            >
              <i class="icon-seat"></i>
            </div>
          </Tooltip>
        </template>
      </div>
    </div>
  </a-modal>
</template>

<script>
import {computed, defineComponent, ref, watch} from "vue";
import Tooltip from "@/components/Tooltip.vue";
import {useGlobalPropertyHook} from "@/hooks/common";

export default defineComponent({
  name: "CheckSeatPositions",
  components: {Tooltip},
  props: {
    visible: Boolean,
    formState: {
      type: Object,
      default: () => ({
        seatApplicationUserCommandList: [],
        floor: '',
      })
    },
  },
  emits: ["close-modal"],
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();
    const seatWrap = ref(null)
    const seatImg = ref(null)

    const locationName = computed(() => props.formState.floor)

    const seatData = ref([])

    const getSeatUsageInfo = () => {
      const params = {
        "uplevel": locationName.value,
        "startDate": "",
        "endDate": ""
      };
      $api.queryUsedSeat(params).then((res) => {
        const data = res.data
        seatData.value = [...data]
      });
    };

    const checkSeat = (data)=>{
      if(props.formState.seatApplicationUserCommandList.find(item=>item.seatName===data && item.isSafety==='0')){
        return 'icon_seat_green'
      }else if(props.formState.seatApplicationUserCommandList.find(item=>item.seatName===data&&item.isSafety==='1')){
        return 'icon_seat_green_border'
      }
    }

    watch(
        () => props.visible,
        (val) => {
          if(val) {
            getSeatUsageInfo()
          }
        }
    )

    const closeModal = () => {
      ctx.emit("close-modal");
    };

    const getRefsAff = () => {
      let app = document.getElementById("main-page");
      if (app) {
        return app;
      }
    };

    return {
      seatData,
      closeModal,
      checkSeat,
      getRefsAff,
      seatWrap,
      seatImg,
      locationName,
    };
  }
});
</script>

<style scoped lang="less">
.wrap {
  position: relative;
  width: 100%;
  height: 35vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  .seatImg{
    position: absolute;
    cursor: move;
    .icon-list{
      position: relative;
      width: 16px;
      height: 16px;
      .icon-seat{
        display: block;
        width: 16px;
        height: 16px;
        &:before{
          display: block;
          content: '';
          width: 100%;
          height: 100%;
        }
      }
      &.icon_seat_blue{
        position: absolute;
        .icon-seat{
          &:before{
            background: url("../../../../assets/images/icon_seat_blue.svg") 100% no-repeat;
            background-size: 100% 100%;
          }
        }
      }
      &.icon_seat_white_border{
        .icon-seat{
          &:before{
            background: url("../../../../assets/images/icon_seat_white_border.svg") 100% no-repeat;
            background-size: 100% 100%;
          }
        }
      }
      &.icon_seat_green{
        .icon-seat{
          &:before{
            background: url("../../../../assets/images/icon_seat_green.svg") 100% no-repeat;
            background-size: 100% 100%;
          }
        }
      }
      &.icon_seat_red{
        .icon-seat{
          &:before{
            background: url("../../../../assets/images/icon_seat_red.svg") 100% no-repeat;
            background-size: 100% 100%;
          }
        }
      }
      &.icon_seat_green_border{
        .icon-seat{
          &:before{
            background: url("../../../../assets/images/icon_seat_green_border.svg") 100% no-repeat;
            background-size: 100% 100%;
          }
        }
      }
      &.icon_seat_red_border{
        .icon-seat{
          &:before{
            background: url("../../../../assets/images/icon_seat_red_border.svg") 100% no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }

  }
}
</style>
<style scoped lang="less">
@import "~@/style/xindi56.less";
@import "~@/style/wanke05.less";
@import "~@/style/wanke04.less";
@import "~@/style/wanke03.less";
</style>
